/* General container styling */
.blog-hero {
  background-color: #f0f0f0;
  padding: 2rem 0;
  text-align: center;
  margin-bottom: 2rem;
}

.blog-hero-title {
  font-size: 2.5rem;
  color: #333333;
  margin: 0;
}

.blog-hero-subtitle {
  font-size: 1.25rem;
  color: #555555;
  margin-top: 0.5rem;
}

/* Form container */
.blog-form-container {
  max-width: 800px;
  margin: 0 auto 2rem;
  padding: 1rem;
  background: #ffffff; /* White background */
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-input, 
.blog-textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #f9f9f9;
  color: #333333;
  outline: none;
  transition: border-color 0.3s, background-color 0.3s;
}

.blog-input::placeholder, 
.blog-textarea::placeholder {
  color: #888888;
}

.blog-input:focus, 
.blog-textarea:focus {
  border-color: #2e7d32; /* Green accent */
}

.blog-submit-button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #2e7d32; /* Green button */
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.blog-submit-button:hover {
  background-color: #1b5e20;
  transform: translateY(-2px);
}

/* Blog posts section */
.blog-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.blog-post-card {
  background: #ffffff; /* White card background */
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.blog-post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5; /* Slightly gray hover background */
}

.blog-post-title {
  font-size: 1.5rem;
  color: #2e7d32; /* Green title */
  margin: 0 0 0.5rem;
}

.blog-post-preview {
  font-size: 1rem;
  color: #555555;
  margin: 0;
}
