/* General Styles */
* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0px;
    /* box-sizing: border-box; */
}

body {
    overflow-x: hidden;
    background-color: #f9f9f9; /* Optional background color */
}

/* Container */
 .container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 20px; /* Adjust top padding to avoid overlap with fixed navbar */
    gap: 50px;
}


/* Header Section */
header h1 {
    font-size: 50px;
    font-weight: bold;
}

header p {
    font-size: 18px;
    color: #555;
}

/* Section Styling */
section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    line-height: 1.5;
    margin-right: -100;
}

section h2 {
    font-weight: 600;
    font-size: 28px;
}

section p {
    font-size: 16px;
    color: #333;
}

section img {
    border-radius: 10px;
    width: 100%;
    max-height: 400px;
    object-fit: cover;
}

/* Strong Emphasis */
strong {
    font-weight: 700;
    color: #04BF94;
}

/* Leaf Animation */
.leaf {
    position: fixed;
    top: 0;
    font-size: 2rem;
    animation: fall linear forwards;
    z-index: 0;
}

@keyframes fall {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(100vh);
        opacity: 0;
    }
}
